<template>
  <v-dialog max-width="800">
    <template v-slot:activator="{on, attrs}">
      <slot name="activator" v-bind:on="on" v-bind:attrs="attrs"></slot>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title v-if="operation === 'create'" class="white--text">Ajouter une entreprise</v-toolbar-title>
        <v-toolbar-title v-else class="white--text">Mettre à jour l'entreprise</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="entrepriseForm">
          <v-text-field :rules="[notNullNorEmpty]" v-model="entreprise.name" label="Nom de l'entreprise"></v-text-field>
          <v-text-field :rules="[notNullNorEmpty]" v-model="entreprise.email" label="E-mail"></v-text-field>
          <v-text-field :rules="[notNullNorEmpty]" v-model="entreprise.tel" label="Téléphone"></v-text-field>
          <v-text-field :rules="[notNullNorEmpty]" v-model="entreprise.siret" label="SIRET"></v-text-field>
          <v-text-field :rules="[notNullNorEmpty]" v-model="entreprise.assureur" label="Assureur"></v-text-field>
          <v-select multiple item-text="name" item-value="id" label="Métiers" v-model="entreprise.metiers" :items="categories"></v-select>
          <v-select label="Conducteurs de travaux" v-model="entreprise.conducteur_travaux"
                  :items="conducteurs_travaux" item-text="fullname"
                  item-value="id"></v-select>
          <v-textarea :rules="[notNullNorEmpty]" v-model="entreprise.adresse" label="Adresse"></v-textarea>
          <span class="body-2">Informations financières</span>
          <v-text-field :rules="[value => value !== null && value.indexOf(' ') === -1 || 'Pas d\'espace dans l\'IBAN.']" v-model="entreprise.iban" label="IBAN"></v-text-field>
          <v-text-field v-model="entreprise.bic" label="BIC"></v-text-field>
          <v-divider></v-divider>
          <v-textarea v-model="entreprise.note" label="Note"></v-textarea>
        </v-form>

      </v-card-text>
      <v-card-actions>
        <v-btn v-if="operation === 'create'" @click="addEntreprise" color="primary">Ajouter l'entreprise</v-btn>
        <v-btn v-else @click="updateEntreprise" color="primary">Mettre à jour l'entreprise</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {ValidationRulesMixin} from "@/mixins/ValidationRulesMixin";
import {EntrepriseRepository} from "@/repository/entreprises";

export default {
  name: "EntrepriseDialog",
  mixins: [ValidationRulesMixin],
  props: {
    operation: {
      type: String,
      default: () => "create",
    },
    entrepriseObject: {
      type: Object,
      default: () => {},
    }
  },
  async mounted() {
    if(this.$store.getters["users/users"].length === 0) {
      await this.$store.dispatch("users/fetchUsers");
    }
    if (this.$store.getters["category/loading_status"] !== "LOADED") {
      await this.$store.dispatch("category/fetchCategory");
    }
  },
  data() {
    let entrepriseModel = this.operation === 'update' ? JSON.parse(JSON.stringify(this.entrepriseObject)) : {
        name: "",
        adresse: "",
        tel: "",
        email: "",
        siret: "",
        conducteur_travaux: null,
        metiers: [],
        note: "",
        iban: "",
      };
    entrepriseModel.metiers = entrepriseModel.metiers.map((metier) => metier.id);

    return {
      repository: new EntrepriseRepository(),
      entreprise: entrepriseModel,
    }
  },
  computed: {
    categories() {
      return this.$store.getters["category/categories"];
    },
    conducteurs_travaux() {
      return [{fullname: "Tous les conducteurs de travaux", id: null}, ...this.$store.getters["users/conducteur_travaux"]];
    },
  },
  methods: {
    async addEntreprise() {
      if (this.$refs.entrepriseForm.validate()) {

        try {
          let result = await this.repository.post(this.entreprise);
          await this.$store.dispatch("annonce/annonceSuccess", "Entreprise créée");
          await this.$router.push(`/entreprises/${result.id}`);
        } catch (e) {
          await this.$store.dispatch("annonce/annonceError", "Une erreur est survenue.");
        }
      }
    },
    async updateEntreprise() {
      if (this.$refs.entrepriseForm.validate()) {
        try {
          await this.repository.update(this.entreprise);
          await this.$store.dispatch("annonce/annonceSuccess", "Entreprise mise à jour");
        } catch (e) {
          await this.$store.dispatch("annonce/annonceError", "Une erreur est survenue");
        } finally {
          this.$emit("reload");
        }
      }
    }
  }
}
</script>

<style scoped>

</style>